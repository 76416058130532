exports.components = {
  "component---src-pages-10-x-js": () => import("./../../../src/pages/10x.js" /* webpackChunkName: "component---src-pages-10-x-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-as-talent-js": () => import("./../../../src/pages/apply-as-talent.js" /* webpackChunkName: "component---src-pages-apply-as-talent-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-next-steps-js": () => import("./../../../src/pages/next-steps.js" /* webpackChunkName: "component---src-pages-next-steps-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-and-policy-js": () => import("./../../../src/pages/privacy-and-policy.js" /* webpackChunkName: "component---src-pages-privacy-and-policy-js" */),
  "component---src-pages-roles-js": () => import("./../../../src/pages/roles.js" /* webpackChunkName: "component---src-pages-roles-js" */),
  "component---src-pages-talent-capabilities-js": () => import("./../../../src/pages/talent-capabilities.js" /* webpackChunkName: "component---src-pages-talent-capabilities-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

